// Vue and core libraries
import { createApp, provide, h } from "vue";
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router';
import Axios from 'axios';

// Plugins and utilities
import { createHttpLink } from 'apollo-link-http';
import { DefaultApolloClient } from '@vue/apollo-composable'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'


// Local modules and components
// import schema from './gql/schema.json';
// import { ApplicationStore } from './stores/application.js';

import Router from './router.js';
import { VueHeadMixin, createHead } from '@unhead/vue'
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from "vue-gtag";

import ImageHelper from './components/helpers/ImageHelper.vue';
import CraftImageHelper from './components/helpers/CraftImageHelper.vue';
import App from './components/App.vue';

// Axios setup
let axiosBaseDomain = import.meta.env.VITE_BASE_URL

Axios.defaults.baseURL = axiosBaseDomain;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';

Axios.defaults.withCredentials = true;

// Apollo Client setup
const httpLink = createHttpLink({
  uri: `${axiosBaseDomain}/api?token=${token}`
});
const cache = new InMemoryCache();
const apolloClient = new ApolloClient({
  cache,
  uri: `${axiosBaseDomain}/api?token=${token}`,
});

const pinia = createPinia()

// App initialization
const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
})
const head = createHead()
app.use(head)

app.use(pinia)
app.use(Router)

app.use(VueReCaptcha, { siteKey: '6Lf1kNMpAAAAAJlRVajBXiKY5jUj2iIS5aCCX6P-',loaderOptions: {
  autoHideBadge: true
} })

app.use(VueGtag, {
  config: { id: "GTM-5V7FMJ37" }
},Router)

app.component('image-helper', ImageHelper);
app.component('craft-image-helper', CraftImageHelper);

// Mount the app
app.mount('#app');
