
import gql from 'graphql-tag'

export const SETTINGS_QUERY = 
    gql`query  {
            contact: globalSet  (handle:"contact") {
                ... on contact_GlobalSet {
                  email: globalEmail
                  address: globalAddress
                  location: googleMapsLink
                  instagram: globalInstagram
                  facebook: globalFacebook
                }
            }
            seo: globalSet  (handle:"seo") {
                ...on seo_GlobalSet{
                    title: globalSeoTitle
                    description: globalSeoDescription
                }
            }
            seoDetails: entries(section: ["about",
          									"home","homes","legal","lifeInArborGreen",
          									"lifeInRolleston","neighbourhood",
          									"propertiesForSale","sections"]) {
              id sectionHandle
              ...on about_Entry{
                title
                seoTitle
                seoDescription
              }
    					...on home_Entry{
                 title
                seoTitle
                seoDescription
              }
    					...on homes_Entry{
                 title
                seoTitle
                seoDescription
              }
    					...on legal_Entry{
                 title
                seoTitle
                seoDescription
              }
    					...on lifeInArborGreen_Entry{
                 title
                seoTitle
                seoDescription
              }
    					...on lifeInRolleston_Entry{
                 title
                seoTitle
                seoDescription
              }
    					...on neighbourhood_Entry{
                 title
                seoTitle
                seoDescription
              }
    					...on propertiesForSale_Entry{
                 title
                seoTitle
                seoDescription
              }
    					...on sections_Entry{
                 title
                seoTitle
                seoDescription
              }
          }
            alertBar: globalSet (handle:"alertBar"){
                ...on alertBar_GlobalSet{
                enabled 
                title: alertBarTitle
                blurb
                alertBarLabel
                link: alertBarLink{
                  id slug title typeHandle
                  ...on EntryInterface{
                    id slug title typeHandle
                  }
                  ...on listing_Entry{
                    listingType
                    lot: title 
                    stage{
                      ... on marketingStages_Category{
                        id number
                      }
                    }
                  }
                }
              }
            }
            docs: entries(section: "globalDocs"){
							...on globalDocuments_Entry{
                globalDocuments {
                   ...on documents_Asset{
                    id title filename link: url size
                  }
                }
              }
            }
            propertyFeatures: entries(section: "listingFeatures"){
							...on featureEntry_Entry{
                propertyFeatures{
                  ...on propertyFeatures_TableRow{
                    description
                  }
                }
                type: featureListingType
                marketingStages: featureMarketingStage{
                  id
                }
                technicalStages:featureTechnicalStages{
                  id
                }
                titleStages: featureTitleStages{
                  id
                }
                lots: selectedLots{
                  ...on listing_Entry{
                    id
                  }
                }
              }
            }
            stages: categories (group: "marketingStages"){	
                ...on marketingStages_Category{
                 id: number label: title default: defaultStage
                 serverId: id
                 subStages {
                  ...on technicalStages_Category{
                    id value: title
                  }
                 }
                 titleStages {
                  ...on titleStages_Category{
                    id value: title
                  }
                 }
               }
            }
            technicalStages: categories (group: "technicalStages"){	
                ...on technicalStages_Category{
                 id title titlesAvailable
               }
            }
            underlyingTitles: categories (group: "titleStages"){	
              ...on titleStages_Category{
               id title number: underLyingTitleLotNumber
             }
            }
            articles: entries(section: "journalEntries"){
              id
            }
            agents: entries(section: "agents"){
                ...on agent_Entry{
                    firstName, lastName
                    email, phone legal
                    profilePhoto{
                    ...on agents_Asset{
                        url
                        filename
                        title
                        width
                        height
                        focalPoint
                        mimeType
                        }
                    }
                }
            }
            builders: entries(section: "builders"){
    					...on builders_Entry{
                id 
                title
                email: builderEmail
                phone: builderPhone
                website: builderWebsite
                logo: builderLogo{
                  ...on builders_Asset{
                    url 
                    filename
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    fallback:  url @transform(width: 800)
                    bg: url @transform(width: 20)
                  }
                }
              }
  					}
            listingEnquiryForm: formieForm(handle: "propertyEnquiry") {
              formFields {
                id
                displayName
                handle
                defaultValue
                placeholder
                required
                type: typeName
                ... on Field_Dropdown {
                  id
                  displayName
                  options {
                    label
                    value
                  }
                  inputTypeName
                }
                ... on Field_MultiLineText {
                    id
                    displayName
                    max
                    maxType
                }
                ...on Field_Checkboxes{
                  id
                  displayName
                  options{
                    ...on FieldOption{
                      label
                      value
                    }
                  }
                }
              }
              settings {
                successByline: submitActionMessageHtml
              }
            }
        }`;