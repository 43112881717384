import gql from 'graphql-tag'

export const LISTINGS_QUERY = 
    gql`query  {
      availableListings: entries (section: "listings", saleStatus:["for-sale","under-offer"]){
        ... on listing_Entry {
          id lot: title 
          type: listingType
          lotType {
            ...on lotTypes_Category{
              id title
            }
          }
          subType
          slug
          lotSize 
          uri
          lotSize
          homeSize
          streetAddress: address
          stage{
            ... on marketingStages_Category{
              id number
            }
          }
          technicalStage{
            ...on technicalStages_Category {
              id title titlesReleased titlesAvailable
            }
          }
          underlyingTitle{
            ...on titleStages_Category{
              id title
            }
          }
          bed bath living garage
          builder {
            ...on builders_Entry{
              id
            }
          }
          modified: dateUpdated
          price
          priceHidden
          saleStatus
          render{
            ...on propertyImages_Asset{
              url 
              filename
              title
              alt
              width
              height
              focalPoint
              mimeType
            }
          }
          salesFlyer{
            ...on documents_Asset{
              id title link: url size
            }
          }
          supplementaryDocuments{
            ...on documents_Asset{
              id title link: url size
            }
          }
        }
      }
      recentlySoldHomes: entries (section: "listings", saleStatus:["sold"], listingType: ["homes-for-sale"], dateUpdated: "> 2 weeks ago"){
      ... on listing_Entry {
      id lot: title 
        type: listingType
        lotType {
          ...on lotTypes_Category{
            id title
          }
        }
        subType
        slug
        lotSize 
        uri
        lotSize
        homeSize
        streetAddress: address
        stage{
          ... on marketingStages_Category{
            id number
          }
        }
        technicalStage{
          ...on technicalStages_Category {
            id title titlesReleased titlesAvailable
          }
        }
        underlyingTitle{
          ...on titleStages_Category{
            id title
          }
        }
        bed bath living garage
        builder {
          ...on builders_Entry{
            id
          }
        }
        modified: dateUpdated
        price
        priceHidden
        saleStatus
        render{
          ...on propertyImages_Asset{
            url 
            filename
            title
            alt
            width
            height
            focalPoint
            mimeType
          }
        }
        salesFlyer{
          ...on documents_Asset{
            id title link: url size
          }
        }
        supplementaryDocuments{
          ...on documents_Asset{
            id title link: url size
          }
        }
      }
    }
    otherListings: entries (section: "listings", saleStatus:["sold","disabled"]){
      ... on listing_Entry {
        id lot: title 
        type: listingType
        lotType {
          ...on lotTypes_Category{
            id title
          }
        }
        subType
        slug
        lotSize 
        uri
        lotSize
        homeSize
        streetAddress: address
        stage{
          ... on marketingStages_Category{
            id number
          }
        }
        technicalStage{
          ...on technicalStages_Category {
            id title titlesReleased titlesAvailable
          }
        }
        underlyingTitle{
          ...on titleStages_Category{
            id title
          }
        }
        bed bath living garage
        builder {
          ...on builders_Entry{
            id
          }
        }
        modified: dateUpdated
        
        saleStatus
        render{
          ...on propertyImages_Asset{
            url 
            filename
            title
            alt
            width
            height
            focalPoint
            mimeType
          }
        }
        salesFlyer{
          ...on documents_Asset{
            id title link: url size
          }
        }
        supplementaryDocuments{
          ...on documents_Asset{
            id title link: url size
          }
        }
      }
    }
  }`;